<template>
    <crossword-game
        class="crossword"
        :words="words"
        :show-play-again="false"
        @gameFinished="gameFinished"
    />
</template>
<!-- game_hm -->
<script>

import "vue-games"
import { socialvue } from "../../../../config/pluginInit"

export default {
  data () {
    return {
      words: [{ "word": "Coffee", "description": "Many people ship it in the morning with milk or cream." }, { "word": "Tea", "description": "British people drink it at 5 o' clock. " }]
    }
  },
  mounted () {
    socialvue.index()
  },
  methods: {
    gameFinished: function (word, lose) {
      console.log("game finished!!!")
      console.log("user was guessing word:", word)
      console.log("she/he/it lost?", lose)
    }
  }
}
</script>
